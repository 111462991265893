// the ~ allows you to reference things in node_modules
@import "~tether/dist/css/tether.css";
@import "~tether/dist/css/tether-theme-basic.css";
@import '~bootstrap/scss/bootstrap-reboot';
@import '~bootstrap/scss/bootstrap';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import 'app-fonts';

* {
  outline: 0;
}

html, body {
  display: block;
  width: 100%;
  height: 100%;
  font-family: "Open Sans", sans-serif;
}

header .nav-link {
  display: inline-block;
  color: #717171;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

header .bg-dark {
  padding: 0.3em;
}

header .navbar-toggler {
  background: #343a40;
  color: #fff;
  padding: 15px 20px;
  margin: 0 auto;
}

header {
  .fdb-terek-title {
    padding: 2em 0 1em 0;

    h1 {
      font: 700 68px PT Serif, serif;
      padding: 0;
      margin: 0;
    }

    .awards {
      max-width: 475px;

      img {
        max-width: 100px;
        max-height: 65px;
      }
    }
  }

  .lang {
    background: #4b5154;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    text-decoration: none;
    color: #fff;
    padding: 3px;
    margin-left: 20px;

    border-radius: 5px;

    .nav-link-flags {
      margin-right: 0 !important;

      img {
        width: 100%;
        max-width: 35px;
        border: 3px solid #fff;

        border-radius: 3px;
      }
    }
  }

  .portfolio-pic img {
    max-height: 450px;
    border: 1px solid #aaa;
    padding: 5px;

    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
  }

  .navbar-nav {
    border: 1px solid #aaa;
    padding: 10px 15px;

    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link,
.nav-item.active a.nav-link {
  background: #17a2b8 !important;
  color: #fff !important;

  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
}

.nav-pills .nav-link {
  color: #717171 !important;
}

.dropdown-item.active, .dropdown-item:active {
  background: #717171 !important;
  color: #fff !important;
}

main {
  background: url('/images/wood_1.png') repeat;
  display: block;
}

main > h1 {
  font: normal 37px PT Serif, serif;
  color: #fff;
  padding: 1.2em 0 0 0;

  text-shadow: 0.07em 0.07em 0 rgba(0, 0, 0, 0.1);

  &::before, &::after {
    content: "§";
    display: inline-block;
    font-size: 0.8em;
    margin: 0 1.6em;

    opacity: 0.2;

    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

main > section {
  display: block;
  max-width: 1300px;
  padding: 2em 0;
  margin: 0 auto;
}

main .owl-carousel {
  width: 100%;
  max-width: 1920px;
  min-height: 800px;
  border: 3px solid #868686;
  margin: 2.5em auto 0 auto;

  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;

  .owl-stage-outer {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
}

main .owl-carousel .item {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  min-height: 800px;
}

main .billboard-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  border: 1px solid #444;
  padding: 0 30px;
  margin: 0 0 20px 0;
}

main .billboard-wrapper .title {
  width: 20%;
  border-right: 1px solid #444;
  text-align: center;
  color: #f45050;
  padding: 12px 30px 12px 0;
}

main .billboard-wrapper .billboard {
  color: #fff9e5;
  padding: 0 12px;
}

main .billboard-wrapper .billboard blockquote {
  padding: 0 !important;
  margin: 0 !important;
}

main section#content {
  background: #fff;
  padding: 20px;

  .navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px auto;
  }

  li.media {
    .media-icon {
      background: #858585;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      margin-right: 1em;

      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;

      i {
        font-size: 32px;
        color: #474747;
      }
    }

    .media-body {
      i {
        color: #474747;
      }
    }
  }
}

main article h1 {
  font: bold 42px 'PT Serif', serif;
  color: #4c4c4c;
}

main article h1 {
  font: bold 42px 'PT Serif', serif;
}

main article p {
  font: normal 16px "Open Sans", Serif;
}

footer {
  background: url("/images/wood_1.png") repeat;
  display: block;
  padding: 2em 0 2em 0 !important;

  section {
    display: block;
    max-width: 1300px;
    margin: 0 auto;
  }

  section h1 {
    font-size: 32px;
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
  }

  section .footer-txt {
    max-width: 700px;
    text-align: center;
    color: #ffffff;
    margin: 0 auto;
  }

  section .logo {
    text-align: center;

    a {
      display: inline-block;
      font-size: 32px;
      color: #fff;
      padding: 5px 15px;
      margin: 5px;
    }

    a:hover {
      text-decoration: none;
    }

    a.home:hover {
      color: #c1694f;
    }

    a.phone:hover {
      color: #00a7d0;
    }

    a.mail:hover {
      color: #ac2925;
    }

    a.facebook:hover {
      color: #2b86ff;
    }

    a.youtube:hover {
      color: #9f191f;
    }
  }
}

.watermark {
  background: #2d3033;
  display: block;
  font: bold 18px "Driving Around", serif;
  text-decoration: none;
  color: #ddd;
  padding: 20px;

  > div {
    max-width: 700px;
    margin: 0 auto;
  }

  .splendidbear-org {
    text-align: right;
  }

  span {
    color: #fff;
  }
}

.watermark:hover {
  text-decoration: none;
  color: #ddd;

  span {
    text-decoration: underline;
  }
}

@media screen and (max-width: 768px) {
  header .navbar-nav .active a.nav-link {
    padding: 5px 10px;
  }

  main > h1 {
    &::before, &::after {
      display: none;
    }
  }

  .watermark a {
    flex-direction: column;

    div,
    img {
      margin-bottom: 5px;
    }
  }

  header .fdb-terek-title .hungarikum {
    flex-direction: column;

    img:last-child {
      margin-left: 0;
    }
  }
}
